.promo {
  margin: rem-calc(32 0 80 0);

  @include breakpoint(medium) {
    display: flex;
    justify-content: space-between;
  }

  img {
    display: none;

    @include breakpoint(medium) {
      display: block;
    }
  }

  hr {
    height: auto;
    margin: rem-calc(0 64);
    border: 0;
    border-right: 1px solid rgba($black, 0.1);
  }
}