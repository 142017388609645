.large {
  color: $primary;
  font-size: rem-calc(24);
}

h1, h2, h3, h4, h5 {
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 300;
  color: inherit;
}

.text-center {
  text-align: center;
}