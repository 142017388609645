@import "../../node_modules/alcedo/scss/mixins";
@import "settings";
@import "../../node_modules/alcedo/scss/style";

@import url('https://fonts.googleapis.com/css?family=Merriweather:400,300,700');
@import url('https://fonts.googleapis.com/css?family=Maven+Pro:400,700');

@import '../../node_modules/baguettebox.js/dist/baguetteBox.min';

@include alcedo-grid;
@include alcedo-type;
@include alcedo-table;
@include alcedo-links;
@include alcedo-button;
@include alcedo-forms;
@include alcedo-image;
@include alcedo-embed;

@import "components/hero";
@import "components/job";
@import "components/promo";
@import "components/section";

@import "global/footer";
@import "global/forms";
@import "global/header";
@import "global/lists";
@import "global/typography";

@import "main";