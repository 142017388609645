a:focus, button:focus {
  outline: 2px solid $primary;
}

.hide, .hd {
  display: none;
}

.ohnohoney {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}