.section {
  padding: rem-calc(80 0);

  &--services {
    @include breakpoint(medium) {
      background: url('../img/dist/home-section-1.jpg') center right / contain no-repeat;
    }
  }

  &--work {
    padding: rem-calc(240 0);
    background: url('../img/dist/home-section-2.jpg') center / cover no-repeat;
    color: $white;
  }

  &--small {
    padding: rem-calc(32 0);
  }

  &--collapse-top {
    padding-top: 0;
  }
}