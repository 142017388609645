.footer {
  background: $primary-dark;
  color: $white;

  a {
    color: $white;
    text-decoration: none;

    &:hover {
      color: $primary;
    }
  }

  &__main {
    padding: rem-calc(48 0);

    h3 {
      font-size: rem-calc(12);
    }

    p {
      font-size: rem-calc(14);
      margin: rem-calc(24);
      
      @include breakpoint(medium) {
        margin: 0;
      }
    }
  }

  &__copyright {
    border-top: 1px solid rgba($white, 0.1);
    padding: rem-calc(16 0);

    p {
      margin: 0;
      font-size: rem-calc(14);
    }
  }
}