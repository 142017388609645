.hero {
  text-align: center;

  &--home {
    background-image: url("../img/dist/home-banner.jpg");
    background-size: cover;
    color: $white;
    padding: rem-calc(160 0);
  }

  &--sub {
    padding: rem-calc(80 0);
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%23eee' fill-opacity='0.4'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    border-top: 1px solid $medium-gray;
    border-bottom: 1px solid $medium-gray;

    h1 {
      @include breakpoint(medium) {
        font-size: rem-calc(32);
      }
    }
  }

  h1, h2, h3, p, ul {
    color: inherit;
  }

  *:last-child {
    margin-bottom: 0;
  }
}