form {
  .input-wrapper {
    display: block;
    margin-bottom: rem-calc(16);
  
    input {
      display: block;
      margin-top: rem-calc(4);
    }
  }
  
  .input-placeholder {
    line-height: 1.5;
  }

  .error {
    display: block;
    padding: rem-calc(16);
    font-weight: bold;
    background: red;
    color: $white;
    margin-bottom: rem-calc(16);
  }
}