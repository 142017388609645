.header {
  padding: rem-calc(16 0);

  @include breakpoint(medium) {
    padding: 0;
  }
  
  .column {
    @include breakpoint(medium) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__logo {
    display: block;
    width: rem-calc(120);
  }

  &__navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(medium) {
      display: block;
    }
  }

  .menu {
    display: none;
    list-style-type: none;
    padding: 0;
    margin: 0;

    @include breakpoint(medium) {
      display: block;
      text-align: right;
    }

    li {
      display: block;

      @include breakpoint(medium) {
        display: inline-block;
      }

      &.active {
        a {
          border-bottom: 5px solid $primary;
        }
      }

      a {
        display: block;
        padding: rem-calc(16 0);
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 5px;
        font-family: $header-font-family;
        font-size: rem-calc(14);
        color: $black;

        @include breakpoint(medium) {
          padding: rem-calc(30 16);
        }

        &:hover {
          color: $primary;
        }
      }
    }
  }

  .toggle {
    text-align: right;

    @include breakpoint(medium) {
      text-align: left;
    }

    &__button {
      position: relative;
      z-index: 0;
      display: inline-block;
      width: 24px;
      height: 24px;
      -webkit-appearance: none;
      outline: none;
      border: none;
      background: none;
      transition: all 0.5s;
      padding: 0;

      @include breakpoint(medium) {
        display: none;
      }

      &:before, &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: $primary;
        display: block;
        transition: .25s all;
        top: 45%;
      }

      &:before {
        transform: translateY(-5px);
      }

      &:after {
        transform: translateY(5px);
      }
    }
  }

  &.is-open {
    background: $white;

    .toggle__button {
      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    .menu {
      display: block;
    }
  }
}