ul {
  &.horizontal {
    margin: 0;
    padding: 0;

    @include breakpoint(medium) {
      text-align: center;
    }

    li {
      list-style-type: none;
      display: block;
      padding: rem-calc(8 0);

      @include breakpoint(medium) {
        display: inline-block;
        padding: rem-calc(0 8);
        border-right: 1px solid $medium-gray;
      }

      &:last-child {
        border-right: 0;
      }
      
      a {
        padding: rem-calc(8 4);
        text-decoration: none;
      }
    }
  }
}